import type { ParsedUrlQuery } from 'querystring';

import type {
  DealerDirectoryFilterNames,
  DealerDirectoryQueryNames,
  QueryParameters,
} from 'components/DealerDirectory/DealerDirectory.typed';
import type { GetManyPayload } from 'data-source/DealerDataSource/DealerDataSource.type';
import type { Franchises } from 'domains/Dealer';
import { QUERY_NAME } from 'components/DealerDirectory/DealerDirectory.typed';
import { FILTER_NAME } from 'components/DealerDirectory/DealerDirectory.typed';
import * as constants from 'components/DealerDirectory/constants';
import { CountiesList, counties } from 'helpers/constants';
import { asString } from 'utils/query-parameters';
import { FilterV2, createFilter } from 'domains/Filter';

/**
 * This function will parse the URL query parameters for known values and type them
 * @param query The unfiltered query returned by the server context
 * @returns type-safe known query parameters
 */
export const mapParametersToQuery = (
  query: ParsedUrlQuery,
  config?: { clearPagination?: boolean },
): Partial<QueryParameters> => {
  const parameters: Partial<QueryParameters> = {};
  if (query.terms) parameters.terms = asString(query.terms);
  if (query.franchises) parameters.franchises = asString(query.franchises);
  if (query.counties) parameters.area = asString(query.counties);
  if (query.from && !config?.clearPagination) {
    parameters.from = asString(query.from);
  }
  return parameters;
};

/**
 * This function will map the type-safe query parameters back to expected url parameters
 * @param query type-safe known query parameters
 * @returns The backwards compatible query
 */
export const mapQueryToParameters = (
  query: Partial<QueryParameters>,
  config?: { clearPagination?: boolean },
): Partial<Record<DealerDirectoryQueryNames, string>> => {
  const parameters: Partial<Record<DealerDirectoryQueryNames, string>> = {};
  if (query.terms) parameters.terms = asString(query.terms);
  if (query.franchises) parameters.franchises = asString(query.franchises);
  if (query.area) parameters.counties = asString(query.area);
  if (query.from && !config?.clearPagination) {
    parameters.from = asString(query.from);
  }
  return parameters;
};

export const mapQueryToPayload = (
  query: Partial<QueryParameters>,
): Partial<GetManyPayload> => ({
  from: query.from,
  terms: query.terms,
  franchises: query.franchises ? [query.franchises] : undefined,
  counties: query.area ? [query.area] : undefined,
});

/**
 * This function maps the counties to the singular shape required by the filters UI
 * @param counties The list of counties
 * @returns mapped list of counties
 */
const addDefaultLocation = (
  counties: CountiesList[],
): FilterV2<'BASE'>['values'] => {
  const defaultValue = {
    displayName: constants.DEFAULT_LOCATION_TEXT,
    value: '',
  };
  return [
    defaultValue,
    ...counties.map((county) => ({
      displayName: county.displayName,
      value: county.value,
    })),
  ];
};

/**
 * This function maps the franchises to the singular shape required by the filters UI
 * @param counties The list of franchises
 * @returns mapped list of franchises
 */
const addDefaultFranchise = (
  franchises: Franchises[],
): FilterV2<'GROUP'>['values'] => {
  const defaultValue = {
    groupDisplayName: '',
    values: [
      {
        displayName: constants.DEFAULT_FRANCHISE_TEXT,
        value: '',
      },
    ],
  };
  return [defaultValue, ...franchises];
};

const defaultDealer: FilterV2<'BASE'>['values'] = [
  {
    displayName: constants.DEFAULT_TERMS_TEXT,
    value: '',
  },
];

/**
 * This function maps data to the singular shape required by the filters UI
 * @param franchises The list of franchises
 * @returns The formatted filters
 */
export const mapDealerFilters = (franchises: Franchises[]): FilterV2[] => {
  const areaFilter = createFilter(
    'BASE',
    FILTER_NAME.AREA,
    addDefaultLocation(counties),
  );
  const franchiseFilter = createFilter(
    'GROUP',
    FILTER_NAME.FRANCHISES,
    addDefaultFranchise(franchises),
  );
  const termsFilter = createFilter('BASE', FILTER_NAME.TERMS, defaultDealer);
  const filters: FilterV2[] = [areaFilter, franchiseFilter, termsFilter];
  return filters;
};

/**
 * This function maps a filter name to the required query key
 * @param filterName the filter name
 * @returns a query key
 */
export const mapfilterNameToQuery = (
  filterName: DealerDirectoryFilterNames,
): DealerDirectoryQueryNames => {
  switch (filterName) {
    case FILTER_NAME.AREA:
      return QUERY_NAME.AREA;
    default:
      return filterName;
  }
};
