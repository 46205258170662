import styled from 'styled-components';

export const Wrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.colors.WHITE};
  padding: ${({ theme }) => `${theme.spacing.S8} ${theme.spacing.M16}`};
  z-index: 1000;
`;

type ContainerProps = {
  cancelable: boolean;
};

export const Container = styled.div<ContainerProps>`
  height: 40px;
  padding: ${({ theme }) => theme.spacing.S8};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  display: grid;
  grid-template-columns: ${({ cancelable }) =>
    cancelable ? `24px 1fr 24px` : `24px 1fr`};
  grid-gap: ${({ theme }) => theme.spacing.S8};
  align-items: center;
`;

// Setting the font size below 16px will cause auto-zooming when focusing on the input field in iOS
export const Input = styled.input`
  outline: none;
  border: 0px;
  padding: 0px;
  width: 100%;
  background-color: transparent;
  ${({ theme }) => theme.fontSize.M16};
`;

export const Button = styled.button`
  color: ${({ theme }) => theme.colors.GREY_DARK};
  cursor: pointer;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.colors.BLUE};
  }
`;
