import type { DealersFilterProps } from 'components/DealerDirectory/components/DealersFilter/DealersFilter.typed';
import { useDealersFilter } from 'components/DealerDirectory/components/DealersFilter/DealersFilter.hook';
import { Mobile } from 'components/Support/Mobile/Mobile';
import { Desktop } from 'components/Support/Desktop/Desktop';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';
import { BottomSheet } from 'components/Toolkit/BottomSheet/BottomSheet';
import { AutocompleteFilter } from 'components/DealerDirectory/components/DealersFilter/components/AutocompleteFilter/AutocompleteFilter';

import * as Styled from 'components/DealerDirectory/DealerDirectory.styled';

const DealersFilter = (props: DealersFilterProps) => {
  const { data, isMobileOpen, handleSelect, openMobile, closeMobile } =
    useDealersFilter(props);
  const { placeholder, selected } = data;

  return (
    <Styled.FilterContainer>
      <Mobile>
        <InputButton
          heightVariant="LARGE"
          isActive={isMobileOpen}
          value={selected.displayName}
          placeholder={placeholder}
          onClick={openMobile}
          dataTestId="dealer-dropdown-button-mobile"
        />
        <BottomSheet
          headerText={selected.displayName}
          onApply={closeMobile}
          askToClose={closeMobile}
          isOpen={isMobileOpen}
          optionSelected={Boolean(selected.value)}
          resetScrollPosition={isMobileOpen}
        >
          <AutocompleteFilter
            placeholder={placeholder}
            initialSelected={selected}
            onSelect={handleSelect}
          />
        </BottomSheet>
      </Mobile>
      <Desktop>
        <AutocompleteFilter
          placeholder={placeholder}
          initialSelected={selected}
          onSelect={handleSelect}
          showLabel
        />
      </Desktop>
    </Styled.FilterContainer>
  );
};

export { DealersFilter };
