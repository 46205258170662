import type { IncomingHttpHeaders } from 'http';

import type { GetManyPayload } from 'data-source/DealerDataSource/DealerDataSource.type';
import type { IDealerRepository } from 'repositories/Dealer/DealerRepository.type';
import { error, ok } from 'domains/Result';
import { DealerDataSource } from 'data-source/DealerDataSource/DealerDataSource';
import * as map from 'repositories/Dealer/DealerRepository.map';
import { donedealServicesGateway } from 'api/api-utils/commonApiUtils';

type SearchResponse = ReturnType<IDealerRepository['search']>;
type GetFranchisesResponse = ReturnType<IDealerRepository['getFranchises']>;
type AutocompleteResponse = ReturnType<IDealerRepository['autocomplete']>;

export class DealerRepository implements IDealerRepository {
  constructor(private dataSource: DealerDataSource) {}

  async search(
    payload: Partial<GetManyPayload>,
    headers?: IncomingHttpHeaders,
  ): SearchResponse {
    try {
      const response = await this.dataSource.getMany(payload, headers);
      const data = map.search(response);
      return ok(data);
    } catch (err) {
      return error(err);
    }
  }

  async getFranchises(headers?: IncomingHttpHeaders): GetFranchisesResponse {
    try {
      const response = await this.dataSource.getFranchises(headers);
      const data = map.franchises(response);
      return ok(data);
    } catch (err) {
      return error(err);
    }
  }

  async autocomplete(
    term: string | null,
    headers?: IncomingHttpHeaders,
  ): AutocompleteResponse {
    const response = await this.dataSource.autocomplete(term, headers);
    return map.autocomplete(response);
  }

  // async getShowroomByName(name: string) {}

  // async getShowroomPhone(id: number) {}

  // async getShowroomFilters(id: number) {}

  // async getShowroomSEO(id: number) {}
}

export const dealerRepository = new DealerRepository(
  new DealerDataSource(donedealServicesGateway),
);
