import { ForwardedRef, forwardRef } from 'react';

import type { ListItemProps } from 'components/ToolkitV2/SearchableDropdown/components/ListItem/ListItem.type';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/components/ListItem/ListItem.styled';

const ListItem = forwardRef(
  (props: ListItemProps, ref: ForwardedRef<HTMLLIElement>) => {
    const { children, disabled, dataTestId, ...rest } = props;
    return (
      <Styled.Item {...rest} ref={ref} data-testid={dataTestId}>
        <Styled.Inner disabled={disabled}>{children}</Styled.Inner>
      </Styled.Item>
    );
  },
);

ListItem.displayName = 'ListItem';

export default ListItem;
