import styled from 'styled-components';

export const Group = styled.div`
  background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
`;

export const Title = styled.span`
  ${({ theme }) => theme.fontSize.B14}
  padding: ${({ theme }) => `${theme.spacing.S4} ${theme.spacing.M16}`};
  color: ${({ theme }) => theme.colors.GREY_DARK};
`;
