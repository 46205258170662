import { useState } from 'react';
import { useRouter } from 'next/router';

import type { DealersFilterProps } from 'components/DealerDirectory/components/DealersFilter/DealersFilter.typed';
import type { DealerDirectoryFilterSelected } from 'components/DealerDirectory/DealerDirectory.typed';
import { ListItem } from 'components/Toolkit/Inputs/CustomSelect';
import { mapParametersToQuery } from 'components/DealerDirectory/helpers/index.mapper';
import { getFilterByValue } from 'domains/Filter';
import * as constants from 'components/DealerDirectory/constants';

export type DealersData = DealersFilterProps['filter'] & {
  placeholder: string;
  selected: DealerDirectoryFilterSelected;
};

const useDealersFilter = (props: DealersFilterProps) => {
  const { filter, onSelect } = props;
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const router = useRouter();
  const query = mapParametersToQuery(router.query);
  const selectedDealer = query.terms;
  const dealers = selectedDealer
    ? getFilterByValue(filter?.values, selectedDealer)
    : null;

  const getDealers = (): DealersData => ({
    ...filter,
    placeholder: filter.values[0].displayName,
    selected: {
      displayName:
        dealers?.displayName || selectedDealer || constants.DEFAULT_TERMS_TEXT,
      value: dealers?.value || null,
    },
  });

  const handleSelect = (item: ListItem) => {
    // `handleSelect` can only be called when the dropdown is open.
    // On desktop the dropdown closes when an item is selected and so no state needed (managed by downshift).
    // On mobile the bottom sheet must be closed programmatically.
    isMobileOpen && setIsMobileOpen(false);
    onSelect(item);
  };

  const openMobile = () => setIsMobileOpen(true);
  const closeMobile = () => setIsMobileOpen(false);

  return {
    data: getDealers(),
    isMobileOpen,
    handleSelect,
    openMobile,
    closeMobile,
  };
};

export { useDealersFilter };
