import type { GetServerSidePropsContext } from 'next';
import type { IncomingHttpHeaders } from 'http';
import type { NextSeoProps } from 'next-seo';
import axios from 'axios';

import type { FilterV2 } from 'domains/Filter';
import type { Dealer, Franchises } from 'domains/Dealer';
import type { Paging } from 'api/types/dealerApiTypes';
import type {
  ServerResponse,
  QueryParameters,
  DealerDirectoryPageProps,
  DealerDirectorySEOContent,
} from 'components/DealerDirectory/DealerDirectory.typed';
import { ServerSideProps, isServerError } from 'types/server';
import {
  mapDealerFilters,
  mapParametersToQuery,
  mapQueryToPayload,
} from 'components/DealerDirectory/helpers/index.mapper';
import {
  formatSEOContent,
  formatSEOMeta,
} from 'components/DealerDirectory/helpers/index.format';
import { isOk } from 'domains/Result';
import { dealerRepository } from 'repositories/Dealer/DealerRepository';
import { formatServerError } from 'helpers/logger';
import { PAGE } from 'helpers/pages';
import ErrorPage from 'pages/_error';
import { DealerDirectory } from 'components/DealerDirectory/DealerDirectory';
import { DefaultLayout } from 'components/Layouts';
import { formatErrorProps } from 'repositories/repository.format';
import MetricService from 'services/MetricService';
import { CDN_STATIC_ASSETS } from 'utils';

const DealerDirectoryPage = (
  props: ServerSideProps<DealerDirectoryPageProps>,
) => {
  if (isServerError(props)) {
    return (
      <ErrorPage
        statusCode={props.code}
        domain={props.domain}
        disableGtmLog={true}
      />
    );
  }
  const { domain = '', ...rest } = props;
  return (
    <DefaultLayout domain={domain} vertical="motor" headerVariant="TRANSPARENT">
      <DealerDirectory {...rest} domain={domain} />
    </DefaultLayout>
  );
};

export default DealerDirectoryPage;

export const getServerSideProps = async (
  context: GetServerSidePropsContext<QueryParameters>,
): ServerResponse => {
  const query = mapParametersToQuery(context.query);
  const headers = context.req.headers;
  const { 'user-agent': userAgent } = headers;
  let franchises: Franchises[] = [];
  let dealers: Dealer[] = [];
  let paging: Paging | null = null;
  let seoMeta: NextSeoProps | null = null;
  let seoContent: DealerDirectorySEOContent | null = null;

  const requestHeaders: IncomingHttpHeaders = {
    ...(userAgent && { 'user-agent': userAgent }),
  };

  const franchisesResponse = await dealerRepository.getFranchises(
    requestHeaders,
  );
  if (isOk(franchisesResponse)) {
    franchises = franchisesResponse.ok;
  } else {
    console.error(
      formatServerError({
        message: 'Error retrieving franchises',
        route: PAGE.FIND_A_DEALER,
        error: franchisesResponse.error,
      }),
    );
    if (axios.isAxiosError(franchisesResponse.error)) {
      return formatErrorProps(franchisesResponse.error.code);
    } else return formatErrorProps();
  }

  const payload = mapQueryToPayload(query);

  const dealersResponse = await dealerRepository.search(
    payload,
    requestHeaders,
  );
  if (isOk(dealersResponse)) {
    dealers = dealersResponse.ok.dealers;
    paging = dealersResponse.ok.paging;
    const count = dealersResponse.ok.count;
    seoContent = formatSEOContent(count, query.area);
    seoMeta = formatSEOMeta(CDN_STATIC_ASSETS, query.area);
  } else {
    console.error(
      formatServerError({
        message: 'Error retrieving dealers',
        route: PAGE.FIND_A_DEALER,
        error: dealersResponse.error,
      }),
    );
    if (axios.isAxiosError(dealersResponse.error)) {
      const metricService = MetricService.get();
      metricService.incrementRequestErrorCount('find-a-dealer', {
        statusCode: dealersResponse.error.response?.status,
      });

      return formatErrorProps(dealersResponse.error.code);
    } else return formatErrorProps();
  }

  const filters: FilterV2[] = mapDealerFilters(franchises);

  return {
    props: {
      seo: { meta: seoMeta, content: seoContent },
      filters,
      dealers,
      paging,
      promoSlot: dealersResponse.ok.promoSlot,
    },
  };
};
