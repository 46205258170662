import { useEffect, useRef, useState } from 'react';
import { useCombobox } from 'downshift';

import type { AutocompleteFilterProps } from 'components/DealerDirectory/components/DealersFilter/components/AutocompleteFilter/AutocompleteFilter.typed';
import useDebounce from 'hooks/useDebounce';
import { useDealerAutoComplete } from 'components/DealerDirectory/queries/useDealerAutoComplete';
import {
  SearchableDropdown,
  highlight,
} from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown';
import { InputButton } from 'components/Toolkit/InputButton/InputButton';

const AutocompleteFilter = (props: AutocompleteFilterProps) => {
  const { initialSelected, onSelect, placeholder, showLabel } = props;
  // Displays 8 items
  const MAX_HEIGHT = '376px';
  // The `inputValue` state is managed outside of downshift as fetching dealers depends on it.
  // Otherwise, a circular dependency would be created between:
  // - inputValue fom useComboBox in useDealerAutoComplete
  // - dealers from useDealerAutoComplete in useComboBox
  const [searchTerm, setSearchTerm] = useState(initialSelected.value ?? '');
  const toggleButtonRef = useRef<HTMLButtonElement>(null);

  const debouncedSearch = useDebounce(searchTerm, 300);
  const {
    data: dealers,
    isLoading,
    isSuccess,
  } = useDealerAutoComplete({
    search: debouncedSearch,
    isExpanded: true,
  });

  const {
    isOpen,
    inputValue,
    setInputValue,
    highlightedIndex,
    getToggleButtonProps,
    getLabelProps,
    getInputProps,
    getMenuProps,
    getItemProps,
  } = useCombobox({
    items: dealers ?? [],
    onInputValueChange: (data) => setSearchTerm(data.inputValue),
    itemToString: (item) => item?.displayName ?? '',
    scrollIntoView: (node) => node?.scrollIntoView({ block: 'nearest' }),
    onSelectedItemChange: ({ selectedItem }) => {
      onSelect(selectedItem);
    },
    stateReducer(_state, actionAndChanges) {
      const { type, changes } = actionAndChanges;
      switch (type) {
        // Without this, the dropdown would close when the searchbox is clicked.
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: true,
          };
        // Without this, the focus is lost when an item is selected/dropdown is closed.
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          toggleButtonRef.current?.focus();
          return changes;
        default:
          return changes;
      }
    },
  });

  useEffect(() => {
    if (!isOpen) setInputValue('');
  }, [isOpen, setInputValue]);

  return (
    <>
      {showLabel && (
        <InputButton
          value={initialSelected.displayName}
          heightVariant="LARGE"
          isActive={isOpen}
          data-testid="dealer-dropdown-button"
          {...getToggleButtonProps({
            placeholder,
            // Passing ref from outside allows for programmatic focusing
            ref: toggleButtonRef,
            // tabIndex is needed to allow the button to be focusable, defaults to "-1"
            tabIndex: 0,
          })}
        />
      )}
      <SearchableDropdown isOpen={!showLabel || isOpen}>
        <label {...getLabelProps()}>
          <SearchableDropdown.Searchbox
            onClear={() => setInputValue('')}
            placeholder={'Type Dealership Name'}
            {...getInputProps()}
            dataTestId="dealer-dropdown-input"
          />
        </label>
        <SearchableDropdown.List
          loadingCount={10}
          isLoading={isLoading}
          maxHeight={showLabel ? MAX_HEIGHT : 'initial'}
          dataTestId="dealer-dropdown-list"
          {...getMenuProps()}
        >
          {isSuccess &&
            dealers.map((dealer, index) => (
              <SearchableDropdown.ListItem
                key={dealer.value}
                highlighted={highlightedIndex === index}
                {...getItemProps({
                  item: dealer,
                  index,
                })}
                dataTestId="dealer-dropdown-listitem"
              >
                {highlight({
                  item: dealer.displayName,
                  searchText: inputValue,
                  selectedItem: initialSelected.displayName,
                })}
              </SearchableDropdown.ListItem>
            ))}
        </SearchableDropdown.List>
      </SearchableDropdown>
    </>
  );
};

export { AutocompleteFilter };
