import type { SearchableDropdownProps } from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown.typed';
import Searchbox from 'components/ToolkitV2/SearchableDropdown/components/Searchbox/Searchbox';
import List from 'components/ToolkitV2/SearchableDropdown/components/List/List';
import ListGroup from 'components/ToolkitV2/SearchableDropdown/components/ListGroup/ListGroup';
import ListItem from 'components/ToolkitV2/SearchableDropdown/components/ListItem/ListItem';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/SearchableDropdown.styled';

const Component = (props: SearchableDropdownProps) => {
  const { children, isOpen, className } = props;
  return (
    <Styled.Relative isOpen={isOpen} className={className}>
      <Styled.Absolute>{children}</Styled.Absolute>
    </Styled.Relative>
  );
};

const SearchableDropdown = Object.assign(Component, {
  Searchbox,
  List,
  ListGroup,
  ListItem,
});

const boldIfMatch = (text: string, searchTerm: string) => {
  if (searchTerm === '') return text;
  const textUpperCase = text.toUpperCase();
  const termUpperCase = searchTerm.toUpperCase();
  const startIndex = textUpperCase.indexOf(termUpperCase);
  if (startIndex === -1) return text;
  const endIndex = startIndex + termUpperCase.length;
  const start = text.substring(0, startIndex);
  const middle = text.substring(startIndex, endIndex);
  const end = text.substring(endIndex, text.length);
  return (
    <span>
      {start}
      <strong>{middle}</strong>
      {end}
    </span>
  );
};

const highlight = ({
  item,
  searchText,
  selectedItem,
  disabled,
}: {
  item: string;
  searchText: string;
  selectedItem?: string;
  disabled?: boolean;
}) => {
  if (disabled) return item;
  if (selectedItem === item) {
    return <strong>{item}</strong>;
  } else return boldIfMatch(item, searchText);
};

export { SearchableDropdown, highlight };
