import styled from 'styled-components';

import type { ListItemProps } from './ListItem.type';

export const Item = styled.li<ListItemProps>`
  background-color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1px;
  :hover {
    border-radius: ${({ theme }) => theme.borderRadius.default};
    background-color: ${({ theme, disabled = false }) =>
      !disabled ? theme.colors.GREY_LIGHTER : theme.colors.WHITE};
  }

  ${({ theme, disabled }) =>
    disabled &&
    `
    color: ${theme.colors.GREY};
  `}

  ${({ theme, highlighted }) =>
    highlighted &&
    `
    background-color: ${theme.colors.GREY_LIGHTER};
  `}
`;

export const Inner = styled.div<ListItemProps>`
  padding: ${({ theme }) => `${theme.spacing.S12} ${theme.spacing.M16}`};
  width: 100%;
  height: 100%;
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  text-align: inherit;

  ${({ theme, disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    color: ${theme.colors.GREY};
  `}
`;
