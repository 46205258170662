import type { ListGroupProps } from 'components/ToolkitV2/SearchableDropdown/components/ListGroup/ListGroup.type';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/components/ListGroup/ListGroup.styled';

const ListGroup = (props: ListGroupProps) => {
  const { children, ...rest } = props;
  return (
    <Styled.Group {...rest}>
      <Styled.Title>{children}</Styled.Title>
    </Styled.Group>
  );
};

export default ListGroup;
