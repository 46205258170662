import { forwardRef, ForwardedRef } from 'react';
import { SearchOutlineIc, CloseOutlineIc } from '@dsch/dd-icons';

import type { SearchboxProps } from 'components/ToolkitV2/SearchableDropdown/components/Searchbox/Searchbox.type';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/components/Searchbox/Searchbox.styled';

const Searchbox = forwardRef(
  (props: SearchboxProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { className, value, dataTestId, onClear, ...rest } = props;
    const cancelable = Boolean(value && value.toString().length > 0);

    return (
      <Styled.Wrapper className={className}>
        <Styled.Container cancelable={cancelable}>
          <SearchOutlineIc height={20} width={20} />
          <Styled.Input
            value={value}
            autoComplete="off"
            type="search"
            ref={ref}
            data-testid={dataTestId}
            {...rest}
          />
          {cancelable && (
            <Styled.Button onClick={onClear} data-testid="searchbox-clear">
              <CloseOutlineIc width={12} height={12} />
            </Styled.Button>
          )}
        </Styled.Container>
      </Styled.Wrapper>
    );
  },
);

Searchbox.displayName = 'Searchbox';

export default Searchbox;
