import { ForwardedRef, forwardRef } from 'react';

import type { ListProps } from 'components/ToolkitV2/SearchableDropdown/components/List/List.type';
import { SkeletonBlock } from 'components/ToolkitV2/Skeleton/Skeleton';
import * as Styled from 'components/ToolkitV2/SearchableDropdown/components/List/List.styled';

const List = forwardRef(
  (props: ListProps, ref: ForwardedRef<HTMLUListElement>) => {
    const {
      children,
      isLoading,
      loadingCount,
      noResults,
      maxHeight,
      dataTestId,
    } = props;
    const count = loadingCount ?? 10;
    if (isLoading) {
      return (
        <Styled.List
          data-testid={`${dataTestId}-loading`}
          ref={ref}
          maxHeight={maxHeight}
        >
          {Array.from({ length: count }, (_, index) => (
            <Styled.SItem key={index}>
              <SkeletonBlock w={'100%'} h={24} />
            </Styled.SItem>
          ))}
        </Styled.List>
      );
    }
    if (noResults) {
      return (
        <Styled.List
          data-testid={`${dataTestId}-no-results`}
          ref={ref}
          maxHeight={maxHeight}
        >
          <Styled.NoResults>No results found</Styled.NoResults>
        </Styled.List>
      );
    }
    return (
      <Styled.List data-testid={dataTestId} ref={ref} maxHeight={maxHeight}>
        {children}
      </Styled.List>
    );
  },
);

List.displayName = 'List';

export default List;
